<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
// import { EventBus } from "@/eventBus.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Tambah Role",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        redirect: "role",
        api: "role",
        activeMenu: "menu",
      },
      title: "Tambah Role",
      items: [
        {
          text: "Role",
        },
        {
          text: "Tambah Role",
          active: true,
        },
      ],
      status: [
        { text: "Aktif", value: "aktif" },
        { text: "Tidak Aktif", value: "tidak aktif" },
      ],
      activeMenu: {},
      refactorPermission: [],
      submitform: false,
      routeBase: "role",
      model: {
        name: null,
        status: null,
      },
      parentMenu: [],
      permisssionParent: [],
      responses: {
        message: null,
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getActiveMenu();
  },

  methods: {
    getMenuChecked(id) {
      if (this.parentMenu.indexOf(id)) {
        this.parentMenu.push(id);
      }
    },

    getMenuCheckedChild(id, parent_id) {
      if (this.parentMenu.indexOf(id)) {
        this.parentMenu.push(id);
      }

      if (this.parentMenu.indexOf(parent_id)) {
        this.parentMenu.push(parent_id);
      }
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
          let _ = this;
          _.model = response.data.data;
          var tampPermission = [];
          _.model.menu_ids.forEach((element) => {
            _.parentMenu.push(element.menu_id);

            let ele = [];
            for (let index in element.permission) {
              ele.push(index);
            }
            tampPermission[element.menu_id] = ele;
          });
          _.permisssionParent = tampPermission;
        });
      }
    },
    getActiveMenu() {
      let _ = this;
      api.get(_.config.activeMenu).then((response) => {
        let _ = this;
        _.activeMenu = response.data.data;
        _.activeMenu.forEach((element) => {
          let data = {};
          data.id = element.id;
          data.name = element.name;
          data.permission = JSON.parse(element.permission);
          data.children = [];
          if (element.children.length > 0) {
            element.children.forEach((chil) => {
              let child = {};
              child.name = chil.name;
              child.id = chil.id;
              child.parent_id = chil.parent_id;
              child.permission = JSON.parse(chil.permission);
              data.children.push(child);
            });
          }

          _.refactorPermission.push(data);
        });
      });
    },
    parsePermission(data) {
      return JSON.parse(data);
    },

    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        let menuPermission = [];
        for (let key in _.permisssionParent) {
          let permission = {};
          for (let index in _.permisssionParent[key]) {
            permission[_.permisssionParent[key][index]] = true;
          }
          menuPermission.push({
            menu_id: parseInt(key),
            permission: permission,
          });
        }

        for (let key in _.parentMenu) {
          const a = menuPermission.find(
            (fruit) => fruit.menu_id == _.parentMenu[key]
          );
          if (!a) {
            menuPermission.push({
              menu_id: _.parentMenu[key],
              permission: {},
            });
          }
        }

        _.model.menus = menuPermission;
        api
          .post(_.config.api, _.model)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Create Success",
              },
            });
            // EventBus.$emit("loadPermission");
          })
          .catch((e) => {
            this.responses.message = e.response.data.message;
          });
      } else {
        delete _.model.id;
        delete _.model.created_at;
        delete _.model.updated_at;
        delete _.model.menu_ids;

        let menuPermission = [];
        for (let key in _.permisssionParent) {
          let permission = {};
          for (let index in _.permisssionParent[key]) {
            permission[_.permisssionParent[key][index]] = true;
          }
          menuPermission.push({
            menu_id: parseInt(key),
            permission: permission,
          });
        }

        for (let key in _.parentMenu) {
          const a = menuPermission.find(
            (fruit) => fruit.menu_id == _.parentMenu[key]
          );
          if (!a) {
            menuPermission.push({
              menu_id: _.parentMenu[key],
              permission: {},
            });
          }
        }

        _.model.menus = menuPermission;

        api
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Edit Success",
              },
            });
            // EventBus.$emit("loadPermission");
          })
          .catch((e) => {
            this.responses.message = e.response.data.message;
          });
      }
    },
  },
  watch: {
    // parentMenu(newValue) {
    //   // console.log(newValue);
    // },
    // permisssionParent(newValue) {
    //   console.log(newValue);
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-if="responses.message"
              variant="danger"
              class="mb-0"
              show
              dismissible
            >
              <p class="mb-0">{{ responses.message }}</p>
            </b-alert>
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                @submit.prevent="handleSubmit(save)"
                class="form-horizontal"
              >
                <div class="row">
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider
                          name="Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Nama"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.name"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="Status"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Status"
                            label-for="input-2"
                          >
                            <b-form-radio-group
                              id="radio-group-1"
                              v-model="model.status"
                              :options="status"
                              name="radio-options"
                            ></b-form-radio-group>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group row">
                          <div class="col-sm-4">
                            <b-button
                              type="button"
                              to="/role"
                              variant="light"
                              class="w-100"
                              >Kembali</b-button
                            >
                          </div>

                          <div class="col-sm-3">
                            <b-button
                              type="submit"
                              variant="primary"
                              class="w-100"
                              >Simpan</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-7">
                    <h6>Pilih Menu</h6>
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-for="(i, index) in refactorPermission"
                        :key="index"
                      >
                        <div class="row">
                          <div class="col-md-12" v-if="i.children.length == 0">
                            <div class="row">
                              <div class="col-md-4">
                                <b-form-checkbox
                                  v-model="parentMenu"
                                  :value="i.id"
                                >
                                  {{ i.name }}
                                </b-form-checkbox>
                              </div>

                              <div class="col-md-8">
                                <b-form-checkbox-group
                                  id="checkbox-group-2"
                                  name="flavour-2"
                                  v-model="permisssionParent[i.id]"
                                >
                                  <b-form-checkbox
                                    v-for="(d, e) in i.permission"
                                    :key="e"
                                    class="text-capitalize"
                                    :value="d"
                                    @change="getMenuChecked(i.id)"
                                  >
                                    {{ d }}
                                  </b-form-checkbox>
                                </b-form-checkbox-group>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12" v-if="i.children.length > 0">
                            <div class="row">
                              <div class="col-md-12">
                                <b-form-checkbox
                                  v-model="parentMenu"
                                  :value="i.id"
                                >
                                  {{ i.name }}
                                </b-form-checkbox>
                              </div>

                              <div class="col-md-12">
                                <b-row
                                  class="ml-2"
                                  v-for="(x, y) in i.children"
                                  :key="y"
                                >
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="col-md-3">
                                        <b-form-checkbox
                                          v-model="parentMenu"
                                          :value="x.id"
                                          @change="getMenuChecked(x.parent_id)"
                                        >
                                          {{ x.name }}
                                        </b-form-checkbox>
                                      </div>

                                      <div
                                        class="col-md-8"
                                        style="margin-left: 40px"
                                      >
                                        <b-form-checkbox-group
                                          id="checkbox-group-2"
                                          name="flavour-2"
                                          v-model="permisssionParent[x.id]"
                                        >
                                          <b-form-checkbox
                                            v-for="(a, b) in x.permission"
                                            :key="b"
                                            class="text-capitalize"
                                            :value="a"
                                            @change="
                                              getMenuCheckedChild(
                                                x.id,
                                                x.parent_id
                                              )
                                            "
                                          >
                                            {{ a }}
                                          </b-form-checkbox>
                                        </b-form-checkbox-group>
                                      </div>
                                    </div>
                                  </div>
                                </b-row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lanng="scss" scoped>
.form-control {
  border: 1.5px solid #ced4da !important;
}
</style>
